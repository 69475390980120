import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
import buttonVariants from '../../../images/buttons/buttonVariants.png';
import buttonVariants2x from '../../../images/buttons/buttonVariants@2x.png';
import primaryButtonException from '../../../images/buttons/primaryButtonException.png';
import primaryButtonException2x from '../../../images/buttons/primaryButtonException@2x.png';
import buttonModal from '../../../images/buttons/buttonModal.png';
import buttonModal2x from '../../../images/buttons/buttonModal@2x.png';
import buttonForm from '../../../images/buttons/buttonForm.png';
import buttonForm2x from '../../../images/buttons/buttonForm@2x.png';
import fullWidthButton from '../../../images/buttons/fullWidthButton.png';
import fullWidthButton2x from '../../../images/buttons/fullWidthButton@2x.png';
import stackedButtons from '../../../images/buttons/stackedButtons.png';
import stackedButtons2x from '../../../images/buttons/stackedButtons@2x.png';
import sideBySide from '../../../images/buttons/sideBySide.png';
import sideBySide2x from '../../../images/buttons/sideBySide@2x.png';
import verticalButtons from '../../../images/buttons/verticalButtons.png';
import verticalButtons2x from '../../../images/buttons/verticalButtons@2x.png';
export const buttonVariantsSet = `${buttonVariants} 560w, ${buttonVariants2x} 1120w`;
export const primaryButtonExceptionSet = `${primaryButtonException} 560w, ${primaryButtonException2x} 1120w`;
export const buttonModalSet = `${buttonModal} 560w, ${buttonModal2x} 1120w`;
export const buttonFormSet = `${buttonForm} 560w, ${buttonForm2x} 1120w`;
export const fullWidthButtonSet = `${fullWidthButton} 560w, ${fullWidthButton2x} 1120w`;
export const stackedButtonsSet = `${stackedButtons} 560w, ${stackedButtons2x} 1120w`;
export const sideBySideSet = `${sideBySide} 560w, ${sideBySide2x} 1120w`;
export const verticalButtonsSet = `${verticalButtons} 560w, ${verticalButtons2x} 1120w`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  buttonVariantsSet,
  primaryButtonExceptionSet,
  buttonModalSet,
  buttonFormSet,
  fullWidthButtonSet,
  stackedButtonsSet,
  sideBySideSet,
  verticalButtonsSet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


















    <h1>{`Button`}</h1>
    <p>{`Buttons allow users to trigger actions and make choices. A Button's text
sets clear expectations of what action will occur when users interact with it.`}</p>
    <ComponentPreview componentName="button--primary" hasReact hasAngular hasHTML titleAttr="Button Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Buttons to communicate the different actions users can take and how they can interact with the page.`}</p>
    <blockquote>
      <p parentName="blockquote">{`As a general rule, use one `}<a parentName="p" {...{
          "href": "#primary-button"
        }}>{`Primary Button`}</a>{` as the main call to action to establish the Button hierarchy on a screen.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Use Button types consistently across products.`}</li>
      <li parentName="ul">{`Avoid using too many Buttons on a page.`}</li>
      <li parentName="ul">{`Use a `}<a parentName="li" {...{
          "href": "/using-spark/components/link"
        }}>{`Link`}</a>{` when the action is to take the user to a new page or to an external site.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <p>{`Buttons have different visual weights that help establish a visual hierarchy of the actions a user can take on a page.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#primary-button"
        }}>{`Primary Button`}</a>{` (Filled Button)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#secondary-button"
        }}>{`Secondary Button`}</a>{` (Outlined Button)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tertiary-button"
        }}>{`Tertiary Button`}</a>{` (Text Button)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#full-width-button"
        }}>{`Full Width Button`}</a></li>
    </ul>
    <img srcSet={buttonVariantsSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbm sprk-u-Width-100" src={buttonVariants} alt="Anatomy of three Button types." />
    <h3>{`Primary Button`}</h3>
    <p>{`It represents the main action we want users to take on a screen. Filled Buttons have the heaviest visual weight. Use in areas of high importance.`}</p>
    <ComponentPreview componentName="button--primary" hasReact hasAngular hasHTML titleAttr="Primary Button Example" mdxType="ComponentPreview" />
    <h4>{`Usage`}</h4>
    <ul>
      <li parentName="ul">{`Use to point out the main call to action on an interface.`}</li>
      <li parentName="ul">{`Use one Primary (Filled) Button per screen to help users understand the action hierarchy of the page and to avoid any confusion.`}</li>
      <li parentName="ul">{`In a process flow, use to show the main action to help users navigate to the next step.`}</li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <img srcSet={buttonModalSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbm sprk-u-Width-100" src={buttonModal} alt="A Primary Button is on the right and represents the main action." />
    <p>{`In this example, the "Submit" Button represents the main action in this Button group.`}</p>
    <p><strong parentName="p">{`Exception:`}</strong></p>
    <ul>
      <li parentName="ul">{`For specific situations where users can choose between two journeys, two Primary (Filled) Buttons may exist side by side.`}</li>
    </ul>
    <img srcSet={primaryButtonExceptionSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbm sprk-u-Width-100" src={primaryButtonException} alt="Two primary Buttons placed side by side." />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h3>{`Secondary Button`}</h3>
    <p>{`It represents independent or alternative actions from the main call to action on
the screen. Outlined Buttons have a medium visual weight and emphasis.`}</p>
    <ComponentPreview componentName="button--secondary" hasReact hasAngular hasHTML titleAttr="Secondary Button Example" mdxType="ComponentPreview" />
    <h4>{`Usage`}</h4>
    <ul>
      <li parentName="ul">{`There can be more than one on a screen.`}</li>
      <li parentName="ul">{`Use for alternative actions that do not navigate the user to the next step in a process.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h3>{`Tertiary Button`}</h3>
    <p>{`It represents the least important action on a screen. They have a similar visual style to a text `}<a parentName="p" {...{
        "href": "/using-spark/components/link"
      }}>{`Link`}</a>{` and
have the lowest visual weight.`}</p>
    <ComponentPreview componentName="button--tertiary" hasReact hasAngular hasHTML titleAttr="Tertiary Button Example" mdxType="ComponentPreview" />
    <h4>{`Usage`}</h4>
    <ul>
      <li parentName="ul">{`For independent or less important actions.`}</li>
      <li parentName="ul">{`Use for sub-tasks where a Primary (Filled) Button is the main action.`}</li>
      <li parentName="ul">{`In a Button group, use Tertiary Buttons for actions with the least visual emphasis that do not require the user's attention.`}</li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <img srcSet={buttonFormSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbm sprk-u-Width-100" src={buttonForm} alt="The back and cancel Button represent least important actions in the Button group." />
    <p>{`In a this example, the "Back" and "Cancel" Buttons represent the least important actions in the Button group.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h3>{`Full Width Button`}</h3>
    <p>{`Use full width Buttons in containers that take up 1/3 of the grid or
smaller, like mobile screens.`}</p>
    <ComponentPreview componentName="button--full-width-at-small-viewport" hasReact hasAngular hasHTML titleAttr="Full Width at Smaller Viewports Button Example." mdxType="ComponentPreview" />
    <h4>{`Usage`}</h4>
    <ul>
      <li parentName="ul">{`A Button that takes up the full width of it's container.`}</li>
      <li parentName="ul">{`On mobile screens, Buttons can be stacked.`}</li>
      <li parentName="ul">{`When stacked, place the primary action on top of secondary actions.`}</li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <img srcSet={fullWidthButtonSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbm sprk-u-Width-50" src={fullWidthButton} alt="A Button that takes up the full width of it's container." />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`States`}</h2>
    <h3>{`Disabled`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#disabled-primary"
        }}>{`Disabled Primary`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#disabled-secondary"
        }}>{`Disabled Secondary`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#disabled-tertiary"
        }}>{`Disabled Tertiary`}</a></li>
    </ul>
    <h4>{`Disabled Primary`}</h4>
    <ComponentPreview componentName="button--disabled" hasReact hasAngular hasHTML titleAttr="Disabled Button Example" mdxType="ComponentPreview" />
    <h4>{`Disabled Secondary`}</h4>
    <ComponentPreview componentName="button--disabled-secondary" hasReact hasAngular hasHTML titleAttr="Disabled Secondary Button Example" mdxType="ComponentPreview" />
    <h4>{`Disabled Tertiary`}</h4>
    <ComponentPreview componentName="button--disabled-tertiary" hasReact hasAngular hasHTML titleAttr="Disabled Tertiary Button Example" mdxType="ComponentPreview" />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h3>{`Spinning`}</h3>
    <p>{`Use when data is being saved or submitted. The spinning indicator will replace the Button text.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#spinning-primary"
        }}>{`Spinning Primary`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#spinning-secondary"
        }}>{`Spinning Secondary`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#spinning-tertiary"
        }}>{`Spinning Tertiary`}</a></li>
    </ul>
    <h4>{`Spinning Primary`}</h4>
    <ComponentPreview componentName="button--spinning" hasReact hasAngular hasHTML titleAttr="Spinning Primary Button Example" mdxType="ComponentPreview" />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h4>{`Spinning Secondary`}</h4>
    <ComponentPreview componentName="button--spinning-secondary" hasHTML hasReact hasAngular titleAttr="Spinning Secondary Button Example" mdxType="ComponentPreview" />
    <h4>{`Spinning Tertiary`}</h4>
    <ComponentPreview componentName="button--spinning-tertiary" hasHTML hasReact hasAngular titleAttr="Spinning Tertiary Button Example" mdxType="ComponentPreview" />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Button Groups`}</h2>
    <p>{`Buttons can be grouped with other Buttons to establish a relationship between them.`}</p>
    <ul>
      <li parentName="ul">{`Group Buttons based on their usage and importance.`}</li>
      <li parentName="ul">{`A max of two Buttons should be in a group.`}<ul parentName="li">
          <li parentName="ul">{`A Primary Button can be grouped with any less visual weight (Secondary or Tertiary) Buttons.`}</li>
          <li parentName="ul">{`Do not mix Primary, Secondary, and Tertiary Buttons in the same Button group.`}</li>
          <li parentName="ul">{`Do not use too many Buttons on a page to avoid overwhelming users.`}</li>
        </ul></li>
    </ul>
    <p><strong parentName="p">{`Exception:`}</strong></p>
    <ul>
      <li parentName="ul">{`For layouts that require more than three calls to action in a group.`}<ul parentName="li">
          <li parentName="ul">{`Use one Primary Button for the main action in combination with two less visual weight Buttons.`}</li>
          <li parentName="ul">{`If using a Primary Button in the group, prefer all other Buttons to be Tertiary.`}</li>
          <li parentName="ul">{`Do not mix more than two styles of Buttons.`}</li>
        </ul></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Style Guidelines`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#text-labels"
        }}>{`Text Labels`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#button-styling"
        }}>{`Button Styling`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#button-alignment"
        }}>{`Button Alignment`}</a></li>
    </ul>
    <h3>{`Text Labels`}</h3>
    <ul>
      <li parentName="ul">{`A Button's text should set clear expectations of what will happen when the user interacts with it.`}</li>
      <li parentName="ul">{`Always use title case (capitalize the first letter of each word).`}</li>
      <li parentName="ul">{`Use task-specific action verbs like: "Add", "Remove", "Read Details" and "Submit."`}</li>
      <li parentName="ul">{`Use fewer than 20 characters.`}</li>
      <li parentName="ul">{`Use compact words. For example, instead of "Submit Your Information Now" use "Submit."`}</li>
      <li parentName="ul">{`Use first-person pronouns. Use "Customize My Loan," instead of "Customize Your Loan."`}</li>
      <li parentName="ul">{`Do not use ampersands (&).`}</li>
      <li parentName="ul">{`Do not wrap Button text into a second line; it reduces legibility and starts looking less like an interactive element.`}</li>
    </ul>
    <h3>{`Button Styling`}</h3>
    <ul>
      <li parentName="ul">{`Text should be `}<strong parentName="li">{`center aligned`}</strong>{`.`}</li>
      <li parentName="ul">{`Buttons should have `}<strong parentName="li">{`16px`}</strong>{` of padding on the top and bottom, and `}<strong parentName="li">{`24px`}</strong>{` on the left and right sides.`}</li>
      <li parentName="ul">{`Width should be between `}<strong parentName="li">{`48x`}</strong>{` and `}<strong parentName="li">{`280px`}</strong>{`.`}</li>
      <li parentName="ul">{`Grouped Buttons should be `}<strong parentName="li">{`16px`}</strong>{` away from each other.`}</li>
      <li parentName="ul">{`Apply the same width to all Buttons in a group.`}</li>
      <li parentName="ul">{`Do not use Buttons in paragraphs or within lines of text. Use
`}<a parentName="li" {...{
          "href": "/using-spark/components/link"
        }}>{`Link`}</a>{`
instead.`}</li>
    </ul>
    <h3>{`Button Alignment`}</h3>
    <p>{`As a rule, place the (Primary) Button `}<strong parentName="p">{`on the right side`}</strong>{` of a screen
to communicate the main action and captures the user's attention.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This rule follows the Gutenberg Principle, which describes how a
user's eye makes a z-pattern when scanning a screen and implies that
placing Buttons to the right communicates the main action to the user.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Buttons should be `}<strong parentName="li">{`right aligned`}</strong>{` to it's parent container.`}</li>
      <li parentName="ul">{`Place the main action `}<strong parentName="li">{`on the
right`}</strong>{` for the most important action in a Button group.`}</li>
      <li parentName="ul"><strong parentName="li">{`Side by Side (Horizontal) Buttons`}</strong>{` - place the Primary (Filled) Button `}<strong parentName="li">{`on the right`}</strong>{` and all other actions to the left.`}</li>
      <li parentName="ul"><strong parentName="li">{`Stack (Vertical) Buttons`}</strong>{` - place the Primary (Filled) Button `}<strong parentName="li">{`on top`}</strong>{` of the other Buttons to indicate the main action.`}</li>
    </ul>
    <p><strong parentName="p">{`Example:`}</strong></p>
    <p>{`In a Button group with three actions, the ‘Submit’ Button:`}</p>
    <ul>
      <li parentName="ul">{`represents the `}<strong parentName="li">{`main action`}</strong>{`.`}</li>
      <li parentName="ul">{`is placed `}<strong parentName="li">{`on the right`}</strong>{` of the other Buttons.`}</li>
      <li parentName="ul">{`is `}<strong parentName="li">{`right aligned`}</strong>{` to it's parent container.`}</li>
    </ul>
    <img srcSet={buttonFormSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbh sprk-u-Width-100" src={buttonForm} alt="A Primary Button is placed on the right of two Tertiary Buttons." />
    <p><strong parentName="p">{`Example:`}</strong></p>
    <p>{`In a Button set, the main action is placed `}<strong parentName="p">{`on the right`}</strong>{`.`}</p>
    <img srcSet={sideBySideSet} className="sprk-u-Measure sprk-u-pas sprk-u-pbm sprk-u-Width-100" src={sideBySide} alt="In a Button set, the Primary Button is on the right." />
    <p><strong parentName="p">{`Example:`}</strong></p>
    <p>{`In a mobile view, two Buttons are stacked and the main action is on the top.`}</p>
    <img srcSet={verticalButtonsSet} className="sprk-u-Measure sprk-u-pam sprk-u-pbs sprk-u-Width-50" src={verticalButtons} alt="On a mobile screen the main action is on top of the secondary action." />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`A Button must have a text label and/or icon, and a container.`}</li>
      <li parentName="ul">{`A Button's text communicates the action that will happen when the
user interacts with it.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If a Button only includes an Icon with no text, alternative text
must be provided.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Adhere to `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/WCAG21/"
          }}>{`Web Content Accessibility Guidelines (WCAG 2.1, Level AA)`}</a>{` for web usage and to specified `}<a parentName="p" {...{
            "href": "https://sparkdesignsystem.com/principles/accessibility-guidelines/"
          }}>{`Accessibility Guidelines`}</a>{` for other digital channels.`}</p>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`We follow, `}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/#button"
        }}>{`WCAG 2.1 Level AA - Button Aria Practices`}</a>{` (Web Content Accessibility Guidelines).`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      